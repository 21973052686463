import { createApp } from 'vue'
import { createPinia } from 'pinia'
import rollbar from '@/rollbar.js'

import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'

import App from './login/LoginApp.vue'
import gtag from '@/gtag.js'
import router from './login/router'
import LiffModal from '@/components/Modal'
import VueHtmlToPaper from '@/lib/VueHtmlToPaper.js'

import '@/assets/base.scss'
import '@/assets/lifficons.css'

const customIconConfig = {
  customIconPacks: {
    liffapp: {
      sizes: {
        default: 'is-size-5',
        small: '',
        medium: 'is-size-3',
        large: 'is-size-1',
      },
      iconPrefix: 'icon-',
      internalIcons: {
        key: 'key-1',
        jewelry: 'jewelry-2',
        creditcard: 'credit-card',
        idcard: 'id-card-1',
        wallet: 'wallet-1',
        other: 'miscellaneous',
      },
    },
  },
}

const pinia = createPinia()
const app = createApp(App).use(Oruga, {
  ...bulmaConfig,
  ...customIconConfig,
})

app.use(VueHtmlToPaper, {
  autoClose: import.meta.env.VITE_DEBUG !== 'true',
})
app.use(LiffModal)
app.use(pinia)
app.use(router)
gtag.setup(app, router, 'login')

app.config.errorHandler = (err, vm, info) => {
  rollbar.error(err)
  throw err // rethrow
}
app.config.globalProperties.$rollbar = rollbar

function at(n) {
  n = Math.trunc(n) || 0
  if (n < 0) n += this.length
  if (n < 0 || n >= this.length) return undefined
  return this[n]
}

const TypedArray = Reflect.getPrototypeOf(Int8Array)
for (const C of [Array, String, TypedArray]) {
  Object.defineProperty(C.prototype, 'at', {
    value: at,
    writable: true,
    enumerable: false,
    configurable: true,
  })
}

app.mount('#app')
