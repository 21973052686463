<script>
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

import liffapi from '@/liffapi'
import utils from '@/utils.js'

export default {
  name: 'ForgotPassword',

  components: {},

  props: {},
  emits: ['close'],

  data: () => ({
    isLoading: false,
    form: {
      email: null,
    },
    isDone: false,
  }),

  computed: {
    ok() {
      return utils.isValidEmail(this.form.email)
    },
  },

  watch: {},

  beforeUnmount() {
    console.log('before unmount')
  },

  async unmounted() {},

  async mounted() {},

  methods: {
    async submit(form) {
      console.debug('submit', form)
      if (!this.ok || this.isLoading) return

      this.isLoading = true
      let auth = getAuth()
      auth.useDeviceLanguage()

      sendPasswordResetEmail(auth, form.email)
        .then(() => {
          this.isDone = true
          liffapi.utils.event('worker_password_reset', null, form.email);
        })
        .catch((error) => {
          let errorCode = error.code
          let errorMessage = error.message
          utils.toast(error.message, 'danger')
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<template>
  <div class="container has-text-centered">
    <div class="firebaseui-auth-container">
      <div
        class="firebaseui-card-header"
        style="background: #012d59; margin: 0 auto; max-width: 362px">
        <img src="@/assets/Liff-marvin-white.png" style="height: 4rem" />
      </div>

      <template v-if="isDone">
        <div class="firebaseui-container mb-3">
          <p class="has-text-left py-4 px-2">
            Keep an eye on your inbox for the password reset link, follow the
            easy steps to create a new password, and don’t hesitate to reach out
            if you need any help!
          </p>
          <p class="has-text-left py-4 px-2">We sent the email to:</p>
          <span class="quote">{{ form.email }}</span>
        </div>

        <nav class="nav-footer">
          <div class="">
            <o-button varian="primary" tag="router-link" to="/" class="">
              Back
            </o-button>
          </div>
        </nav>
      </template>

      <form v-else @submit.prevent="submit(form)">
        <div class="firebaseui-container mb-3">
          <p class="mb-3">Recover Password</p>

          <p class="has-text-left">
            Get instructions sent to this email that explain how to reset your
            password
          </p>

          <o-field label="Email" class="has-text-left">
            <template #label><span class="is-size-7">Email</span></template>
            <o-input
              v-model="form.email"
              expanded
              type="email"
              name="email"
              autocomplete="username" />
          </o-field>
        </div>

        <nav class="nav-footer">
          <div class="">
            <o-button tag="router-link" to="/" class=""> Cancel </o-button>
          </div>

          <div class="">
            <o-button
              variant="primary"
              :loading="isLoading"
              :disabled="isLoading || !ok"
              native-type="submit"
              @click="submit(form)">
              Send
            </o-button>
          </div>
        </nav>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.quote {
  background-color: #f5f5f5;
  color: #4a4a4a;
  font-family: monospace;
  font-size: 0.875em;
  overflow-x: auto;
  padding: 1.25rem 1.5rem;
  word-wrap: normal;
}

.nav-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
</style>
