<script>
import { auth } from '@/firebase' // Import the auth object from your firebase.js file
import { signInWithEmailAndPassword } from 'firebase/auth'

let errors = [
  "The email and password you entered don't match",
  'Enter your email address to continue',
  "That email address isn't correct",
]

export default {
  name: 'SignIn',

  props: {},

  emits: ['close', 'authenticated'],

  data: () => ({
    authForm: {
      email: '',
      password: '',
    },
    isLoading: false,
    errors: null,
    redirectForm: {
      path: null,
      application: null,
    },
  }),

  computed: {
    ok() {
      return true
    },
  },

  watch: {},

  beforeUnmount() {
    console.log('before unmount')
  },

  async unmounted() {},

  async mounted() {
    console.log(this.$route.query)
    if (this.$route.query?.r) {
      this.redirectForm.path = this.$route.query.r
      this.redirectForm.application = this.$route.query?.a
    }
  },

  methods: {
    async handleSubmit() {
      this.isLoading = true
      this.errors = null

      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          this.authForm.email,
          this.authForm.password
        )
        // Signed in
        const user = userCredential.user
        console.log('User signed in:', user)

        // You can add additional logic here, such as redirecting to a dashboard
        // this.$router.push('/dashboard')
        this.$emit('authenticated', userCredential, this.redirectForm)
      } catch (error) {
        console.error('Error signing in:', error)
        this.errors = error.message
      } finally {
        this.isLoading = false
      }
    },

    handleTroubleSigningIn() {
      // Implement the logic for handling trouble signing in
      console.log('Trouble signing in')
    },
  },
}
</script>

<template>
  <div class="container has-text-centered">
    <div class="firebaseui-auth-container">
      <div class="firebaseui-card-header" style="">
        <img
          src="@/assets/Liff-marvin-white.png"
          style="height: 4rem"
          alt="Liff logo" />
      </div>

      <div class="firebaseui-container">
        <h1 class="firebaseui-title">Sign in</h1>

        <form class="p-4 has-text-left" @submit.prevent="handleSubmit">
          <o-field label="Email">
            <o-input
              v-model="authForm.email"
              data-test="formEmail"
              expanded
              type="email"
              name="email"
              autocomplete="username" />
          </o-field>

          <o-field label="Password">
            <o-input
              v-model="authForm.password"
              data-test="formPassword"
              expanded
              type="password"
              name="password"
              autocomplete="current-password" />
          </o-field>

          <div class="signin-error-wrapper">
            <p v-if="errors" class="has-text-danger">
              {{ errors }}
            </p>
          </div>

          <div class="is-flex is-justify-content-space-between">
            <div class="">
              <router-link
                to="/trouble"
                class=""
                href="#"
                @click.prevent="handleTroubleSigningIn">
                Trouble signing in?
              </router-link>
            </div>
            <div class="">
              <o-button
                variant="primary"
                data-test="submitBtn"
                :loading="isLoading"
                :disabled="isLoading || !ok"
                native-type="submit"
                @click="handleSubmit">
                Sign In
              </o-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
