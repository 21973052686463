import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'

const firebaseConfig = JSON.parse(import.meta.env.VITE_APP_FIREBASE)

const app = firebase.initializeApp(firebaseConfig)
const auth = firebase.auth(app)

export default app
export { auth }
